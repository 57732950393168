import gql from 'graphql-tag';

export default gql`
query getAllUsersOfGroup($gid: Int!) {
  teams_items(where: {_or: [{folder: {group_id: {_eq: $gid}}}, {project: {group_id: {_eq: $gid}}}, {page: {group_id: {_eq: $gid}}}]}) {
    team_id
    team {
      name
      users_roles_teams {
        user {
          user_id
          full_name
          email
          active
          deactivated
          email_confirmed
          profile_pictures
        }
        role {
          name
        }
      }
    }
  }
}
`;
