var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tab-content" },
    [
      _c(
        "b-row",
        { staticClass: "mb-2" },
        [
          _c(
            "b-col",
            {
              staticClass: "d-flex align-items-center",
              attrs: { cols: "12", "offset-sm": "1", sm: "11" }
            },
            [
              _c("h3", { staticClass: "mb-0" }, [
                _vm._v(_vm._s(_vm.printName()))
              ])
            ]
          ),
          _c("b-col", { attrs: { cols: "12", "offset-sm": "1", sm: "11" } }, [
            _c("hr")
          ])
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mb-2" },
        [
          _c(
            "b-col",
            {
              staticClass: "d-flex align-items-center",
              attrs: { cols: "12", "offset-sm": "1", sm: "11" }
            },
            [
              _c("h3", { staticClass: "mb-0 text-uppercase" }, [
                _vm._v(_vm._s(_vm.$t("groupManagement.settings")))
              ])
            ]
          )
        ],
        1
      ),
      _vm.isSysAdmin
        ? _c(
            "b-row",
            [
              _c(
                "b-col",
                {
                  staticClass: "d-flex",
                  attrs: { cols: "12", "offset-sm": "1", sm: "11" }
                },
                [
                  _c(
                    "div",
                    { staticClass: "mb-2 border-red" },
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          attrs: {
                            id: "disable-account",
                            name: "disableAccount",
                            size: "lg",
                            value: true,
                            "unchecked-value": false
                          },
                          on: { change: _vm.disableAccountBox },
                          model: {
                            value: _vm.group.settings.disableAccount,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.group.settings,
                                "disableAccount",
                                $$v
                              )
                            },
                            expression: "group.settings.disableAccount"
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("groupManagement.disableAccountLabel")
                              ) +
                              " "
                          )
                        ]
                      ),
                      _c("b-form-text", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("groupManagement.disableAccountDesc")
                            ) +
                            " "
                        )
                      ])
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _vm.isSysAdmin
        ? _c(
            "b-row",
            [
              _c(
                "b-col",
                {
                  staticClass: "d-flex",
                  attrs: { cols: "12", "offset-sm": "1", sm: "11" }
                },
                [
                  _c(
                    "div",
                    { staticClass: "mb-2 border-red" },
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          attrs: {
                            id: "is-test-account",
                            name: "isTestAccount",
                            size: "lg",
                            value: true,
                            "unchecked-value": false
                          },
                          on: { change: _vm.changeTestAccountSettings },
                          model: {
                            value: _vm.group.settings.isTestAccount,
                            callback: function($$v) {
                              _vm.$set(_vm.group.settings, "isTestAccount", $$v)
                            },
                            expression: "group.settings.isTestAccount"
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("groupManagement.testAccountLabel")
                              ) +
                              " "
                          )
                        ]
                      ),
                      _c("b-form-text", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("groupManagement.testAccountDesc")) +
                            " "
                        )
                      ])
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _vm.isSysAdmin
        ? _c(
            "b-row",
            [
              _c(
                "b-col",
                {
                  staticClass: "d-flex",
                  attrs: { cols: "12", "offset-sm": "1", sm: "11" }
                },
                [
                  _c(
                    "div",
                    { staticClass: "mb-2" },
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          attrs: {
                            id: "enable-custom-fonts",
                            name: "enableCustomFonts",
                            size: "lg",
                            value: true,
                            "unchecked-value": false
                          },
                          on: { change: _vm.enableCustomFonts },
                          model: {
                            value: _vm.group.settings.enableCustomFonts,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.group.settings,
                                "enableCustomFonts",
                                $$v
                              )
                            },
                            expression: "group.settings.enableCustomFonts"
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("groupManagement.enableCustomFontsLabel")
                              ) +
                              " "
                          )
                        ]
                      ),
                      _c("b-form-text", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("groupManagement.enableCustomFontsDesc")
                            ) +
                            " "
                        )
                      ])
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _vm.isSysAdmin
        ? _c(
            "b-row",
            { staticClass: "mb-4" },
            [
              _c(
                "b-col",
                {
                  staticClass: "d-flex align-items-center mb-2",
                  attrs: { cols: "12", "offset-sm": "1", sm: "7" }
                },
                [
                  _vm.group.settings.enableCustomFonts
                    ? _c("modal-create-and-edit-custom-fonts", {
                        attrs: {
                          group: _vm.group,
                          customFont: null,
                          tableIndex: null,
                          edit: false
                        },
                        on: { addCustomFont: _vm.addCustomFont }
                      })
                    : _vm._e()
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "12", "offset-sm": "1", sm: "11" } },
                [
                  _vm.group.settings.enableCustomFonts &&
                  _vm.group.settings.customFonts &&
                  _vm.group.settings.customFonts.length !== 0
                    ? _c(
                        "div",
                        [
                          _c("b-table", {
                            key: _vm.number,
                            ref: "fontTable",
                            staticClass: "table-view fonts-table",
                            attrs: {
                              fields: _vm.fontFields,
                              items: _vm.group.settings.customFonts,
                              "sort-icon-left": "",
                              "show-empty": "",
                              borderless: "",
                              dark: ""
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "table-colgroup",
                                  fn: function(scope) {
                                    return _vm._l(scope.fields, function(
                                      field
                                    ) {
                                      return _c("col", {
                                        key: field.key,
                                        style: { width: field.width }
                                      })
                                    })
                                  }
                                },
                                {
                                  key: "cell(image)",
                                  fn: function() {
                                    return [
                                      _c("b-icon", {
                                        staticClass: "mr-2",
                                        staticStyle: { color: "#b9b9b9" },
                                        attrs: {
                                          icon: "paperclip",
                                          "aria-hidden": "true"
                                        }
                                      })
                                    ]
                                  },
                                  proxy: true
                                },
                                {
                                  key: "cell(name)",
                                  fn: function(data) {
                                    return [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "item",
                                          on: {
                                            click: function($event) {
                                              return _vm.$emit(
                                                "selected",
                                                data.item
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(data.item.fontFamily) +
                                              " "
                                          )
                                        ]
                                      )
                                    ]
                                  }
                                },
                                {
                                  key: "cell(font_weight)",
                                  fn: function(data) {
                                    return [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "item",
                                          on: {
                                            click: function($event) {
                                              return _vm.$emit(
                                                "selected",
                                                data.item
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(data.item.fontWeight) +
                                              " "
                                          )
                                        ]
                                      )
                                    ]
                                  }
                                },
                                {
                                  key: "cell(font_style)",
                                  fn: function(data) {
                                    return [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "item",
                                          on: {
                                            click: function($event) {
                                              return _vm.$emit(
                                                "selected",
                                                data.item
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(data.item.style) + " "
                                          )
                                        ]
                                      )
                                    ]
                                  }
                                },
                                {
                                  key: "cell(font_display)",
                                  fn: function(data) {
                                    return [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "item",
                                          on: {
                                            click: function($event) {
                                              return _vm.$emit(
                                                "selected",
                                                data.item
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(data.item.fontDisplay) +
                                              " "
                                          )
                                        ]
                                      )
                                    ]
                                  }
                                },
                                {
                                  key: "cell(font_fallback)",
                                  fn: function(data) {
                                    return [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "item",
                                          on: {
                                            click: function($event) {
                                              return _vm.$emit(
                                                "selected",
                                                data.item
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(data.item.fallbackFont) +
                                              " "
                                          )
                                        ]
                                      )
                                    ]
                                  }
                                },
                                {
                                  key: "cell(dot)",
                                  fn: function(data) {
                                    return [
                                      _c(
                                        "b-dropdown",
                                        {
                                          staticClass: "context-menu",
                                          attrs: {
                                            "toggle-class": "dot-button",
                                            "no-caret": "",
                                            dropup: "",
                                            "no-flip": "",
                                            right: ""
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "button-content",
                                                fn: function() {
                                                  return [
                                                    _c("b-icon", {
                                                      attrs: {
                                                        icon:
                                                          "three-dots-vertical",
                                                        "aria-hidden": "true",
                                                        scale: ".8"
                                                      }
                                                    })
                                                  ]
                                                },
                                                proxy: true
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        },
                                        [
                                          _c(
                                            "b-dropdown-item",
                                            {
                                              directives: [
                                                {
                                                  name: "b-modal",
                                                  rawName: "v-b-modal",
                                                  value: `modal-edit-custom-font-group-${_vm.group.group_id}-${data.index}`,
                                                  expression:
                                                    "`modal-edit-custom-font-group-${group.group_id}-${data.index}`"
                                                }
                                              ]
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "modals.editCustomFont.dropdown"
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "b-dropdown-item",
                                            {
                                              directives: [
                                                {
                                                  name: "b-modal",
                                                  rawName: "v-b-modal",
                                                  value: `modal-delete-custom-font-${data.index}`,
                                                  expression:
                                                    "`modal-delete-custom-font-${data.index}`"
                                                }
                                              ]
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "modals.deleteCustomFont.dropdown"
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _vm.group.settings.enableCustomFonts
                                        ? _c(
                                            "modal-create-and-edit-custom-fonts",
                                            {
                                              attrs: {
                                                group: _vm.group,
                                                customFont: data.item,
                                                tableIndex: data.index,
                                                edit: true
                                              },
                                              on: {
                                                editCustomFont:
                                                  _vm.editCustomFont
                                              }
                                            }
                                          )
                                        : _vm._e(),
                                      _c("modal-delete-custom-font", {
                                        attrs: {
                                          customFont: data.item,
                                          tableIndex: data.index
                                        },
                                        on: {
                                          deleteCustomFont: _vm.deleteCustomFont
                                        }
                                      })
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              3500505870
                            )
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-row",
        { staticClass: "mb-2" },
        [
          _c(
            "b-col",
            {
              staticClass: "d-flex align-items-center",
              attrs: { cols: "12", "offset-sm": "1", sm: "11" }
            },
            [
              _c("h3", { staticClass: "mb-0 text-uppercase" }, [
                _vm._v(_vm._s(_vm.$t("groupManagement.customDomain")))
              ])
            ]
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mb-5" },
        [
          _c(
            "b-col",
            {
              staticClass: "d-flex align-items-center",
              attrs: { cols: "12", "offset-sm": "1", sm: "7" }
            },
            [
              _c("b-form-input", {
                style: _vm.isSysAdmin
                  ? { border: "1px solid rgba(220, 83, 83, 0.7)" }
                  : "",
                attrs: {
                  id: "custom-domain",
                  disabled:
                    !_vm.isSysAdmin && _vm.group.user_role !== "accountManager",
                  "aria-describedby": "custom-domain-help-block",
                  debounce: "500"
                },
                model: {
                  value: _vm.domain,
                  callback: function($$v) {
                    _vm.domain = $$v
                  },
                  expression: "domain"
                }
              })
            ],
            1
          ),
          _c(
            "b-col",
            {
              staticClass: "d-flex align-items-center mt-2",
              attrs: { cols: "12", "offset-sm": "1", sm: "7" }
            },
            [
              _c("modal-add-custom-domain", {
                attrs: {
                  group: _vm.group,
                  initialDomain: _vm.group.settings.customDomain,
                  isSysAdmin: _vm.isSysAdmin
                },
                on: { addCustomDomain: _vm.addCustomDomain }
              }),
              _vm.isSysAdmin || _vm.group.user_role === "accountManager"
                ? _c(
                    "b-button",
                    {
                      staticClass: "red-button-transparent ml-3",
                      on: {
                        click: function($event) {
                          return _vm.saveCustomDomain(_vm.domain)
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("buttons.saveDomain")) + " ")]
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "b-col",
            {
              staticClass: "d-flex align-items-center",
              attrs: { cols: "12", "offset-sm": "1", sm: "7" }
            },
            [
              _vm.group.settings.customDomain !== "" &&
              _vm.group.settings.customDomain !== _vm.group.domain
                ? _c(
                    "b-form-text",
                    { attrs: { id: "custom-domain-help-block" } },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("groupManagement.customDomainInfo", {
                              domain: _vm.group.settings.customDomain
                            })
                          ) +
                          " "
                      ),
                      _c("b-icon", {
                        directives: [
                          {
                            name: "b-modal",
                            rawName: "v-b-modal",
                            value:
                              "modal-custom-domain-info-group-" +
                              _vm.group.group_id,
                            expression:
                              "'modal-custom-domain-info-group-'+group.group_id"
                          }
                        ],
                        staticClass: "mr-2 top--3",
                        attrs: {
                          icon: "exclamation-circle-fill",
                          "aria-hidden": "true",
                          scale: "1"
                        }
                      }),
                      _c("modal-custom-domain-info", {
                        attrs: { group: _vm.group }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mb-2" },
        [
          _c(
            "b-col",
            {
              staticClass: "d-flex align-items-center",
              attrs: { cols: "12", "offset-sm": "1", sm: "3" }
            },
            [
              _c("h3", { staticClass: "mb-0 text-uppercase" }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("groupManagement.childGroups")) + " "
                )
              ])
            ]
          ),
          _c(
            "b-col",
            {
              staticClass: "align-content-end",
              attrs: { cols: "12", sm: "8" }
            },
            [
              _c(
                "div",
                { staticClass: "float-right d-flex" },
                [
                  _c(
                    "b-input-group",
                    { staticClass: "search-group d-flex align-items-center" },
                    [
                      _c("b-icon", {
                        attrs: {
                          icon: "search",
                          "aria-hidden": "true",
                          scale: "1"
                        }
                      }),
                      _c("b-form-input", {
                        attrs: {
                          id: "filter-input",
                          type: "search",
                          placeholder: _vm.$t("webmag.search")
                        },
                        model: {
                          value: _vm.childGroupsFilter,
                          callback: function($$v) {
                            _vm.childGroupsFilter = $$v
                          },
                          expression: "childGroupsFilter"
                        }
                      })
                    ],
                    1
                  ),
                  _vm.canCreateSubgroups
                    ? _c("modal-add-new-group", {
                        attrs: {
                          tableIndex: _vm.group.group_id,
                          isSysAdmin: _vm.isSysAdmin,
                          userPrivileges: _vm.userPrivileges,
                          groupCreateError: _vm.groupCreateError
                        },
                        on: {
                          createGroup: _vm.createGroup,
                          resetErrorMsg: _vm.resetErrorMsg
                        }
                      })
                    : _vm._e(),
                  _vm.showGroupCreateInfo && !_vm.isSysAdmin
                    ? _c("modal-group-create-info", {
                        on: {
                          "close-info-modal": function($event) {
                            _vm.showGroupCreateInfo = false
                          }
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ]
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mb-5" },
        [
          _c(
            "b-col",
            { attrs: { cols: "12", "offset-sm": "1", sm: "11" } },
            [
              _c("b-table", {
                key: _vm.number,
                staticClass: "table-view items-table",
                attrs: {
                  fields: _vm.groupFields,
                  items: _vm.group.children,
                  filter: _vm.childGroupsFilter,
                  "sort-icon-left": "",
                  "show-empty": "",
                  borderless: "",
                  dark: ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "empty",
                    fn: function() {
                      return [
                        _c(
                          "div",
                          { staticClass: "text-center my-2" },
                          [
                            _c("b-icon", {
                              staticClass: "mr-2 top--3",
                              attrs: {
                                icon: "exclamation-circle-fill",
                                "aria-hidden": "true",
                                scale: "1"
                              }
                            }),
                            _vm._v(
                              " " + _vm._s(_vm.$t("table.noChildGroups")) + " "
                            )
                          ],
                          1
                        )
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "emptyfiltered",
                    fn: function() {
                      return [
                        _c(
                          "div",
                          { staticClass: "table-center my-2" },
                          [
                            _c("b-icon", {
                              staticClass: "mr-2 top--3",
                              attrs: {
                                icon: "exclamation-circle-fill",
                                "aria-hidden": "true",
                                scale: "1"
                              }
                            }),
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("table.noFilteredGroups")) +
                                " "
                            )
                          ],
                          1
                        )
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "table-colgroup",
                    fn: function(scope) {
                      return _vm._l(scope.fields, function(field) {
                        return _c("col", {
                          key: field.key,
                          style: { width: field.width }
                        })
                      })
                    }
                  },
                  {
                    key: "cell(name)",
                    fn: function(data) {
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "item",
                            on: {
                              click: function($event) {
                                return _vm.$emit("selected", data.item)
                              }
                            }
                          },
                          [_vm._v(" " + _vm._s(data.item.name) + " ")]
                        )
                      ]
                    }
                  },
                  {
                    key: "cell(dot)",
                    fn: function(data) {
                      return [
                        _vm.isSysAdmin
                          ? _c(
                              "b-dropdown",
                              {
                                staticClass: "context-menu",
                                attrs: {
                                  "toggle-class": "dot-button",
                                  disabled:
                                    !_vm.isSysAdmin &&
                                    !_vm.$hasPrivileges(
                                      _vm.$privileges(
                                        "CAN_ADD_SUBGROUP_TO_GROUP"
                                      ),
                                      _vm.isSysAdmin,
                                      _vm.userPrivileges
                                    ),
                                  "no-caret": "",
                                  dropup: "",
                                  "no-flip": "",
                                  right: ""
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "button-content",
                                      fn: function() {
                                        return [
                                          _c("b-icon", {
                                            attrs: {
                                              icon: "three-dots-vertical",
                                              "aria-hidden": "true",
                                              scale: ".8"
                                            }
                                          })
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [
                                _c(
                                  "b-dropdown-item",
                                  {
                                    directives: [
                                      {
                                        name: "b-modal",
                                        rawName: "v-b-modal",
                                        value: `modal-remove-group-${data.item.group_id}`,
                                        expression:
                                          "`modal-remove-group-${data.item.group_id}`"
                                      }
                                    ]
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("modals.removeGroup.dropdown")
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _c("modal-remove-group", {
                          staticClass: "d-inline-block",
                          attrs: {
                            groupIndex: data.item.group_id,
                            groupName: data.item.name,
                            groupChildren: data.item.children
                          },
                          on: { removeGroup: _vm.removeGroup }
                        })
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mb-2" },
        [
          _c(
            "b-col",
            {
              staticClass: "d-flex align-items-center",
              attrs: { cols: "12", "offset-sm": "1", sm: "3" }
            },
            [
              _c("h3", { staticClass: "mb-0 text-uppercase" }, [
                _vm._v(" " + _vm._s(_vm.$t("groupManagement.groupUsers")) + " ")
              ])
            ]
          ),
          _c(
            "b-col",
            {
              staticClass: "align-content-end",
              attrs: { cols: "12", sm: "8" }
            },
            [
              _c(
                "div",
                { staticClass: "float-right d-flex" },
                [
                  _c(
                    "b-input-group",
                    { staticClass: "search-group d-flex align-items-center" },
                    [
                      _c("b-icon", {
                        attrs: {
                          icon: "search",
                          "aria-hidden": "true",
                          scale: "1"
                        }
                      }),
                      _c("b-form-input", {
                        attrs: {
                          id: "filter-input",
                          type: "search",
                          placeholder: _vm.$t("webmag.search")
                        },
                        model: {
                          value: _vm.groupUserFilter,
                          callback: function($$v) {
                            _vm.groupUserFilter = $$v
                          },
                          expression: "groupUserFilter"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "green-button-transparent border-0 ml-3",
                      on: {
                        click: function($event) {
                          _vm.showAddUserModal = true
                        }
                      }
                    },
                    [
                      _c("b-icon", {
                        staticClass: "mr-2 top--3",
                        attrs: {
                          icon: "plus-circle-fill",
                          "aria-hidden": "true",
                          scale: "1"
                        }
                      }),
                      _vm._v(" " + _vm._s(_vm.$t("buttons.addMember")) + " ")
                    ],
                    1
                  ),
                  _vm.showAddUserModal
                    ? _c("modal-add-new-user", {
                        attrs: {
                          isSysAdmin: _vm.isSysAdmin,
                          userPrivileges: _vm.userPrivileges,
                          "current-user-role": _vm.group.user_role,
                          inTeam: false
                        },
                        on: {
                          addUser: _vm.addUser,
                          cancel: function($event) {
                            _vm.showAddUserModal = false
                          }
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ]
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mb-5" },
        [
          _c(
            "b-col",
            { attrs: { cols: "12", "offset-sm": "1", sm: "11" } },
            [
              _c("b-table", {
                staticClass: "table-view users-table",
                attrs: {
                  fields: _vm.userFields,
                  items: _vm.users,
                  filter: _vm.groupUserFilter,
                  "tbody-tr-class": _vm.rowClass,
                  "sort-icon-left": "",
                  "show-empty": "",
                  borderless: "",
                  dark: ""
                },
                on: { "sort-changed": _vm.handleSortChange },
                scopedSlots: _vm._u([
                  {
                    key: "empty",
                    fn: function() {
                      return [
                        _c(
                          "div",
                          { staticClass: "text-center my-2" },
                          [
                            _c("b-icon", {
                              staticClass: "mr-2 top--3",
                              attrs: {
                                icon: "exclamation-circle-fill",
                                "aria-hidden": "true",
                                scale: "1"
                              }
                            }),
                            _vm._v(" " + _vm._s(_vm.$t("table.noUsers")) + " ")
                          ],
                          1
                        )
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "emptyfiltered",
                    fn: function() {
                      return [
                        _c(
                          "div",
                          { staticClass: "table-center my-2" },
                          [
                            _c("b-icon", {
                              staticClass: "mr-2 top--3",
                              attrs: {
                                icon: "exclamation-circle-fill",
                                "aria-hidden": "true",
                                scale: "1"
                              }
                            }),
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("table.noFilteredUsers")) +
                                " "
                            )
                          ],
                          1
                        )
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "table-colgroup",
                    fn: function(scope) {
                      return _vm._l(scope.fields, function(field) {
                        return _c("col", {
                          key: field.key,
                          style: { width: field.width }
                        })
                      })
                    }
                  },
                  {
                    key: "cell(image)",
                    fn: function(data) {
                      return [
                        data.item.image !== null
                          ? _c("b-avatar", {
                              attrs: { size: "32px", src: data.item.image }
                            })
                          : _c("b-avatar", { attrs: { size: "32px" } })
                      ]
                    }
                  },
                  {
                    key: "cell(name)",
                    fn: function(data) {
                      return [_vm._v(" " + _vm._s(data.item.name) + " ")]
                    }
                  },
                  {
                    key: "cell(email)",
                    fn: function(data) {
                      return [_vm._v(" " + _vm._s(data.item.email) + " ")]
                    }
                  },
                  {
                    key: "cell(role)",
                    fn: function(data) {
                      return [_vm._v(" " + _vm._s(data.item.role.name) + " ")]
                    }
                  },
                  {
                    key: "cell(status)",
                    fn: function(data) {
                      return [_vm._v(" " + _vm._s(data.item.status) + " ")]
                    }
                  },
                  {
                    key: "cell(dot)",
                    fn: function(data) {
                      return [
                        _c("user-management-dropdown", {
                          key: _vm.number,
                          attrs: {
                            data: data,
                            tableIndex: _vm.group.group_id,
                            group: _vm.group,
                            isSysAdmin: _vm.isSysAdmin,
                            "current-user-id": _vm.currentUserId,
                            userPrivileges: _vm.userPrivileges,
                            teamIndex: _vm.group.group_id,
                            userObject: data.item,
                            users: _vm.users
                          },
                          on: {
                            editUser: _vm.editUser,
                            deleteUser: _vm.deleteUser,
                            activateUser: _vm.activateUser,
                            sendInvitationReminder: _vm.sendInvitationReminder
                          }
                        })
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _vm.isSysAdmin
        ? _c(
            "b-row",
            { staticClass: "mb-2 team-member" },
            [
              _c(
                "b-col",
                {
                  staticClass: "d-flex align-items-center",
                  attrs: { cols: "12", "offset-sm": "1", sm: "3" }
                },
                [
                  _c(
                    "h3",
                    {
                      staticClass: "mb-0 text-uppercase",
                      style: { color: "rgba(220, 83, 83, 0.7)" }
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("groupManagement.teamUsers")) + " "
                      )
                    ]
                  )
                ]
              ),
              _c(
                "b-col",
                {
                  staticClass: "align-content-end",
                  attrs: { cols: "12", sm: "8" }
                },
                [
                  _c(
                    "div",
                    { staticClass: "float-right d-flex" },
                    [
                      _c(
                        "b-input-group",
                        {
                          staticClass: "search-group d-flex align-items-center"
                        },
                        [
                          _c("b-icon", {
                            attrs: {
                              icon: "search",
                              "aria-hidden": "true",
                              scale: "1"
                            }
                          }),
                          _c("b-form-input", {
                            attrs: {
                              id: "filter-input",
                              type: "search",
                              placeholder: _vm.$t("webmag.search")
                            },
                            model: {
                              value: _vm.teamUserFilter,
                              callback: function($$v) {
                                _vm.teamUserFilter = $$v
                              },
                              expression: "teamUserFilter"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _vm.isSysAdmin
        ? _c(
            "b-row",
            { staticClass: "mb-5 team-member" },
            [
              _c(
                "b-col",
                { attrs: { cols: "12", "offset-sm": "1", sm: "11" } },
                [
                  _c("b-table", {
                    key: _vm.number,
                    staticClass: "table-view users-table",
                    attrs: {
                      fields: _vm.userTeamFields,
                      items: _vm.teamUsers,
                      filter: _vm.teamUserFilter,
                      "sort-icon-left": "",
                      "show-empty": "",
                      borderless: "",
                      dark: ""
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "empty",
                          fn: function() {
                            return [
                              _c(
                                "div",
                                { staticClass: "text-center my-2" },
                                [
                                  _c("b-icon", {
                                    staticClass: "mr-2 top--3",
                                    attrs: {
                                      icon: "exclamation-circle-fill",
                                      "aria-hidden": "true",
                                      scale: "1"
                                    }
                                  }),
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("table.noUsers")) + " "
                                  )
                                ],
                                1
                              )
                            ]
                          },
                          proxy: true
                        },
                        {
                          key: "emptyfiltered",
                          fn: function() {
                            return [
                              _c(
                                "div",
                                { staticClass: "table-center my-2" },
                                [
                                  _c("b-icon", {
                                    staticClass: "mr-2 top--3",
                                    attrs: {
                                      icon: "exclamation-circle-fill",
                                      "aria-hidden": "true",
                                      scale: "1"
                                    }
                                  }),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("table.noFilteredUsers")) +
                                      " "
                                  )
                                ],
                                1
                              )
                            ]
                          },
                          proxy: true
                        },
                        {
                          key: "table-colgroup",
                          fn: function(scope) {
                            return _vm._l(scope.fields, function(field) {
                              return _c("col", {
                                key: field.key,
                                style: { width: field.width }
                              })
                            })
                          }
                        },
                        {
                          key: "cell(image)",
                          fn: function(data) {
                            return [
                              data.item.image !== null
                                ? _c("b-avatar", {
                                    attrs: {
                                      size: "32px",
                                      src: data.item.image
                                    }
                                  })
                                : _c("b-avatar", { attrs: { size: "32px" } })
                            ]
                          }
                        },
                        {
                          key: "cell(name)",
                          fn: function(data) {
                            return [_vm._v(" " + _vm._s(data.item.name) + " ")]
                          }
                        },
                        {
                          key: "cell(email)",
                          fn: function(data) {
                            return [_vm._v(" " + _vm._s(data.item.email) + " ")]
                          }
                        },
                        {
                          key: "cell(role)",
                          fn: function(data) {
                            return [_vm._v(" " + _vm._s(data.item.team) + " ")]
                          }
                        },
                        {
                          key: "cell(status)",
                          fn: function(data) {
                            return [
                              _vm._v(" " + _vm._s(data.item.status) + " ")
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      1147755631
                    )
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }