import gql from 'graphql-tag';

export default gql`
mutation updateGroupSettings(
  $group_id: Int!
  $settings: jsonb!
) {
  update_groups(
    where: {
      group_id: {_eq: $group_id}
    }, 
    _set: {settings: $settings}
  ) {
    affected_rows
  }
}
`;
