import gql from 'graphql-tag';

export default gql`
mutation updatePirschDomain($obj: updatePirschDomainInput!) {
  updatePirschDomain(object: $obj) {
    domain
    pirsch_code
  }
}
`;
