import gql from 'graphql-tag';

export default gql`
mutation addGroupMember($obj: AddGroupMemberInput!) {
  addGroupMember(object: $obj) {
    group_id
    role_id
    user_id
  }
}
`;
