import gql from 'graphql-tag';

export default gql`
query getAllUsersOfGroup($gid: Int!) {
  users_roles_groups(where: {group_id: {_eq: $gid}}) {
    user {
      user_id
      full_name
      email
      active
      deactivated
      email_confirmed
      profile_pictures
    }
    role {
      role_id
      handle
      name
      description
    }
  }
}
`;
