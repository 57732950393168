import gql from 'graphql-tag';

export default gql`
mutation updateCustomDomain(
  $group_id: Int!
  $settings: jsonb!
  $custom_domain: String!
) {
  update_groups(
    where: {
      group_id: {_eq: $group_id}
    }, 
    _set: {settings: $settings, domain: $custom_domain}
  ) {
    affected_rows
  }
}
`;
