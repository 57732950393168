import gql from 'graphql-tag';

export default gql`
mutation updateUserActivate(
  $userId: Int!
) {
  update_users(
    where: {
      user_id: {_eq: $userId}
    }, 
    _set: {deactivated: false}
  ) {
    affected_rows
  }
}
`;
